<template>
    <form action="/search-results" :target="formTarget">
        <div class="d-flex flex-column flex-md-row align-items-stretch align-items-md-center m-n1">
            <div class="flex-grow-1 m-1">
                <vue-search group-class="m-0" name="q" label="What can we help you find?" v-model="searchTerm"></vue-search>
            </div>
            <button class="btn btn-sm btn-primary m-1" type="submit">Search</button>
        </div>
    </form>
</template>
<script>
    import VueSearch from './../../Core/Global/Components/VueForms/VueSearch';
    export default {
        props: [
            'formTarget',
            'searchField',
        ],
        components: {
            VueSearch,
        },
        data(){
            return {
                searchTerm: this.searchField
            }
        }
    }
</script>