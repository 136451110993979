// Combine Custom Site JS into one file
require('./content');

import Vue from 'vue';
import VueModal from './../../Vue/Core/Global/Components/VueModals/VueModal.vue';
import SearchForm from './../../Vue/Custom/Site/SearchForm.vue';

let siteHeader = new Vue({
    el: '#site-header',
    components: {
        VueModal,
        SearchForm
    },
    data: {
        showSearch: false,
    },
    computed: {
        getSearchFieldValue(){
            let urlParams = new URLSearchParams(window.location.search);
            return urlParams.has('q') ? urlParams.get('q') : '';
        }
    },
    methods: {
        resetModal() {
            this.showSearch = false;
            this.searchField = '';
        },
        openSearch() {
            this.showSearch = true;
            document.querySelector('.site-search-modal input[name="q"]').focus();
        },
        openMobileMenu() {
            document.querySelector('.hn-list').setAttribute('data-expanded', 'true');
            document.body.style.overflowY = 'hidden';
        },
        closeMobileMenu() {
            document.querySelector('.hn-list').removeAttribute('data-expanded');
            document.body.style.overflowY = '';
        },
        expandToggle() {
            let depth = event.target.getAttribute('data-depth'),
                expanded = event.target.getAttribute('data-expanded') === 'true'

            if (expanded) {
                this.removeExpandedFromAll();
                this.addExpandedToParents(event, depth);
            } else {
                this.removeExpandedFromAll();
                this.addExpandedToSelfAndParents(event, depth);
            }
        },
        removeExpandedFromAll() {
            document.querySelectorAll('.hn-family')?.forEach(family => {
                family.removeAttribute('data-expanded')
            })
        },
        addExpandedToParents(event, depth) {
            let ancestorDepth = depth - 1;
        
            for (let i = 0; i <= ancestorDepth; ++i) {
                event.target.closest(`[data-depth="${i}"].hn-family`).setAttribute('data-expanded', 'true');
            }
        },
        addExpandedToSelfAndParents(event, depth) {
            for  (let i = 0; i <= depth; ++i) {
                event.target.closest(`[data-depth="${i}"].hn-family`).setAttribute('data-expanded', 'true');
            }
        }
    }
})